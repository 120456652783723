import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageTitle from "../../components/page-title"

class Agradecimento extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Agradecimento" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PageTitle>Agradecimento</PageTitle>
          <p style={{ marginTop: "50px" }}>A sua subscrição foi cancelada.</p>
          <p>
            Obrigado pelo voto de confiança e pedimos desculpa por, de alguma
            maneira, não termos correspondido às suas melhores expectativas.
            Acredite que não foi por falta de vontade e de esforços e que
            continuaremos a trabalhar para evoluir. Pode deixar-nos, mas
            continue a pensar, a refletir e a potenciar comportamentos e ações
            que o melhorem a si e que também ajudem os outros.
          </p>
          <p>Cumprimentos.</p>
          <em>Vasco Samouco.</em>
        </div>
      </Layout>
    )
  }
}

export default Agradecimento

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
